export default {
    searchplaceholder: "ਸਰੋਜ ਖੋਜ",
    languages: "ਭਾਸ਼ਾਵਾਂ" ,
    home: "ਹੋਮ ਸਕ੍ਰੀਨ",
    product:"ਉਤਪਾਦ",
    support:"ਸਮਰਥਨ",
    recentnews:"ਤਾਜ਼ਾ ਖ਼ਬਰਾਂ",
    about:"ਬਾਰੇ",
    contact:"ਸੰਪਰਕ ਕਰੋ",
    bookmarks: "ਬੁੱਕਮਾਰਕ",
    tabs: "ਟੈਬ",
    settings: "ਸੈਟਿੰਗਜ਼",
    more: "ਹੋਰ",
    profile: "ਪ੍ਰੋਫਾਈਲ",
    logout: "ਲੌਗਆਉਟ",
    emailNotifications: "ਈਮੇਲ ਸੂਚਨਾਵਾਂ",
    pushNotifications: "ਪੁਸ਼ ਸੂਚਨਾਵਾਂ",
    whatsappNotifications: "ਵਾਟਸਐਪ ਸੂਚਨਾਵਾਂ ਮਾਤਰ",
    history: "ਇਤਿਹਾਸ",
    changeLanguage: "ਭਾਸ਼ਾ ਬਦਲੋ",
    feedback: "ਫੀਡਬੈਕ",
    Termsandconditions: "ਨਿਯਮ ਅਤੇ ਸ਼ਰਤਾਂ",
    slogan:"ਸਿਰਫ਼ ਇੱਕ ਕਲਿੱਕ ਨਾਲ ਸੰਸਾਰ ਦੀ ਪੜਚੋਲ ਕਰੋ ਅਤੇ ਆਪਣੀ ਖੋਜ ਸ਼ੁਰੂ ਕਰੋ",
    trending:"ਅੱਜ ਪ੍ਰਚਲਿਤ"
    
}