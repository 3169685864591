export default {
    searchplaceholder: "सरोज शोध",
    languages: "भाषा",
    home: "होम स्क्रीन",
    product:"उत्पादने",
    support:"समर्थन",
    recentnews:"अलीकडील बातम्या",
    about:"बद्दल",
    contact:"संपर्क",
    bookmarks: "चिन्हांकित",
    tabs: "टॅब्स",
    settings: "सेटिंग्ज",
    more: "अधिक",
    profile: "प्रोफाइल",
    logout: "लॉगआउट",
    emailNotifications: "ईमेल सूचना",
    pushNotifications: "पुश सूचना",
    whatsappNotifications: "व्हाट्सएप सूचना",
    history: "इतिहास",
    changeLanguage: "भाषा बदला",
    feedback: "प्रतिसाद",
    Termsandconditions: "अटी आणि अटी",
    slogan:"फक्त एका क्लिकने जग एक्सप्लोर करा आणि तुमचा शोध सुरू करा",
    trending:"आज ट्रेंडिंग"
}