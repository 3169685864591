import React, { useState } from 'react'
import './Support.css'
import supportside from '../Assets/support .png'
import { Card, Col, Row } from 'react-bootstrap';
import ai from '../Assets/Group 1000007191.png';
import bookpink from '../Assets/Group 1000007192.png';
import news from "../Assets/Group 1000007195.png";
import picksearch from '../Assets/Group 1000007190.png';
import picktrans from '../Assets/Group 1000007193.png';
import pinkdic from '../Assets/Group 1000007194.png';
import Header1 from '../Header/Header1';



const Support = () => {
    const [search, setSearch] = useState("");

    const SupportDate = [
        {
            img: picksearch,
            Title: "Super Search Engines",
        },
        {
            img: ai,
            Title: "Artificial Intelligences Super Search",
        },
        {
            img: bookpink,
            Title: "Encyclopedias Super Search",
        },
        {
            img: picktrans,
            Title: "Translators Super Search",
        },
        {
            img: pinkdic,
            Title: "Dictionaries Super Search",
        },
        {
            img: news,
            Title: "News Super Search",
        },
    ]

    return (
        <>
            <Header1 />
            <Row >
                <Col sm={12} md={6} lg={12}>
                    <div className='background_clr d-flex align-items-center justify-content-center flex-column'>
                        <div className='help_font'>How can we help?</div>
                        <div className='InputContainer mt-3'>
                            <input
                                type="text"
                                name="text"
                                className="input"
                                id="input"
                                placeholder="Saroji Search"
                                autoComplete='off'
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            // onKeyDown={handleKeyDown}
                            />
                        </div>
                        <img src={supportside} width={"350px"} height={'350px'} style={{ position: "absolute", right: "8%", top: "14%" }} />

                    </div>
                </Col>
                <Col sm={12} md={6} lg={12}>
                    <div className='help_font pt-3 text-center' style={{ color: "#EA596E" }}>For which product do you need Support</div>
                </Col>
                <Col sm={12} md={6} lg={12}>
                    <div className='d-flex flex-row justify-content-center align-items-center flex-wrap gap-4 pt-3'>
                        {SupportDate.map((x, i) => (
                            <Col sm={12} md={6} lg={3}>
                                <Card className='shadow'>
                                    <Card.Body>
                                        <img src={x.img} alt='' width={30} />  {x.Title}
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default Support
