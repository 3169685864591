import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";

import bengali from "./bn";
import gujarati from "./gu";
import hi from "./hi";
import kn from "./kn";
import ml from "./ml";
import marathi from "./mr";
import oriya from "./or";
import punjabi from "./pa";
import tamil from "./ta";
import telugu from "./te";
import en from "./en";
import ur from "./ur";

const resources = {
    en: {
        translation: en,
    },
    gu: {
        translation: gujarati,
    },
    kn: {
        translation: kn,
    },
    ur: {
        translation: ur,
    },
    ta: {
        translation: tamil,
    },
    te: {
        translation: telugu,
    },
    bn: {
        translation: bengali,
    },
    hi: {
        translation: hi,
    },
    ml: {
        translation: ml,
    },
    mr: {
        translation: marathi,
    },
    or: {
        translation: oriya,
    },
    pa: {
        translation: punjabi,
    },
};

i18n
    .use(Backend)
    .use(initReactI18next)
    .init(
        {
            defaultNS: "translation",
            compatibilityJSON: "v3",
            lng: "en",
            debug: true,
            fallbackLng: "en",
            resources,
            interpolation: {
                escapeValue: false, // react already safes from xss
            },
            react: {
                useSuspense: false,
            },
        },
        (err, t) => {
            if (err) return console.log("something went wrong while loading", err);
        }
    );

export default i18n;