import { EndPointURL } from "./ServiceURL";

export async function GetAllCountry(body) {
    try {
      const response = await fetch(`${EndPointURL}/common/countries`, {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
        }
      });
      if (response.status === 200) {
        return await response.json();
      } else if (
        response.status === 401 ||
        response.status === 400 ||
        response.status === 404
      ) {
        return await response.json();
      } else if (response.status === 403) {
        return await response.json();
      } else {
        var errorResponse = await response.json();
        throw new Error(errorResponse.error);
      }
    } catch (error) {}
  }

  export async function GetAllLanguage(body) {
    try {
      const response = await fetch(`${EndPointURL}/common/languages`, {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      if (response.status === 200) {
        return await response.json();
      } else if (
        response.status === 401 ||
        response.status === 400 ||
        response.status === 404
      ) {
        return await response.json();
      } else if (response.status === 403) {
        return await response.json();
      } else {
        var errorResponse = await response.json();
        throw new Error(errorResponse.error);
      }
    } catch (error) {}
  }