// import { ADD_ITEM } from "../Action/Action";
import initalstate from "./Initial";

const ItemReducers = (state = initalstate, action) => {
  switch (action.type) {
    case "ADD_ITEM":
      return {
        ...state,
        BrowsersData: [...state.BrowsersData, action.payload],
      };
    // case "REMOVE_ITEM":
    //   return {
    //     ...state,
    //     bookmark: state.bookmark.filter((item) => item.id !== action.payload),
    //   };
    default:
      return state;
  }
};

export default ItemReducers;
