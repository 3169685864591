export default {
    searchplaceholder: "સરોજ શોધ",
    languages: "ભાષાઓ",
    home: "હોમ સ્ક્રીન",
    product:"ઉત્પાદનો",
    bookmarks: "બુકમાર્ક્સ",
    support:"આધાર",
    recentnews:"તાજેતરના સમાચાર",
    settings:"સેટિંગ્સ",
    about:"વિશે",
    contact:"સંપર્ક કરો",
    tabs: "ટૅબ્સ",
    settings: "સેટિંગ્સ",
    more: "વધુ",
    profile: "પ્રોફાઇલ",
    logout: "લૉગઆઉટ",
    emailNotifications: "ઇમેઇલ સૂચનાઓ",
    pushNotifications: "પુશ સૂચનાઓ",
    whatsappNotifications: "વોટ્સએપ સૂચનાઓ",
    history: "ઇતિહાસ",
    changeLanguage: "ભાષા બદલો",
    feedback: "પ્રતિસાદ",
    Termsandconditions: "શરતો અને નિયમો",
    slogan:"માત્ર એક ક્લિકથી વિશ્વનું અન્વેષણ કરો અને તમારી શોધ શરૂ કરો",
    trending:"આજે ટ્રેન્ડિંગ"
}