import React, { useState, useRef, useEffect } from 'react'
import "./Home.css"
import girl from "../Assets/image 32.png"

import searchio from "../Assets/searchio.png"
import yah from "../Assets/Yahoo.png"
import google from "../Assets/G.png"
import luco from "../Assets/Lycos.png"
import duc from "../Assets/Duck.png"
import vcode from "../Assets/y.png"

import aioio from "../Assets/aioio.png"
import wikiiio from "../Assets/wikiiio.png"
import translateiooio from "../Assets/translateiooio.png"
import dictionaryiioio from "../Assets/dictionaryiioio.png"
import newsssio from "../Assets/newsssio.png"
import BingLogo from "../Assets/cornerFirst.png"
import AdobeFireflyLogo from "../Assets/cornersecond.png"
import MicrosoftTranslatorLogo from "../Assets/cornerthird.png"
import WikipediaLogo from "../Assets/cornerfourth.png"
import DailyTimesIndiaLogo from "../Assets/cornerfifht.png"
import { Navigate, useNavigate } from 'react-router-dom'
import laptopImage from "../Assets/lapBall.png"
import roundCircle from "../Assets/ROUNDEDRIO.png"
import one from "../Assets/one.png"
import two from "../Assets/two.png"
import three from "../Assets/three.png"
import four from "../Assets/four.png"
import five from "../Assets/five.png"
import six from "../Assets/six.png"
import andriod from "../Assets/img/android.png"
import laphone from "../Assets/img/laphone.png"
import openaiLogo from '../Assets/img/openai.png';
import geminiLogo from '../Assets/img/google_gemini.png';
import wikipediaLogo from '../Assets/img/wikipedia.png';
import everipediaLogo from '../Assets/img/everipedia.png';
import dictionaryLogo from '../Assets/img/dictionary.png';

import Footer from '../Footer/Footer'


const Home = ({ loginCall }) => {


    const [searchImg, setSearchImg] = useState(false);

    const navigate = useNavigate();

    const AuthUser = localStorage.getItem("Authuser");

    const GotoSearch = () => {
        if (AuthUser) {
            navigate("/search")
        } else {
            loginCall()
        }
    }

    const cards = [
        { title: 'Bing', imgSrc: BingLogo },
        { title: 'Adobe Firefly', imgSrc: AdobeFireflyLogo },
        { title: 'Microsoft Translator', imgSrc: MicrosoftTranslatorLogo },
        { title: 'Wikipedia', imgSrc: WikipediaLogo },
        { title: 'Daily Times India', imgSrc: DailyTimesIndiaLogo },
    ];

    const contents = [
        { head: "Bing", sub: "Bing offers precise search results, image, video & voice search integration with Microsoft services." },
        { head: "adobe firefly", sub: "Adobe Firefly generates AI-powered images and text effects, enhancing creative workflows with intuitive tools." },
        { head: "Microsoft Translator", sub: "Microsoft Translator provides real-time text and speech translation across multiple languages for seamless communication." },
        { head: "WIKIPEDIA", sub: "A collaborative online encyclopedia providing information on a vast array of topics." },
        { head: "DAILY TIMES INDIA", sub: "Daily Times India delivers up-to-date news and analysis on national and international events, catering to a diverse readership." },
    ];

    const listItems = [
        { name: 'Super Search Engines', number: one },
        { name: 'Artificial Intelligences Super Search', number: two },
        { name: 'Encyclopedias Super Search', number: three },
        { name: 'Translators Super Search', number: four },
        { name: 'Dictionaries Super Search', number: five },
        { name: 'News Super Search', number: six },
    ];

    const logos = [
        { src: openaiLogo, alt: 'OpenAI', name: 'OpenAI' },
        { src: geminiLogo, alt: 'Google Gemini', name: 'Google Gemini' },
        { src: wikipediaLogo, alt: 'Wikipedia', name: 'Wikipedia' },
        { src: everipediaLogo, alt: 'Everipedia', name: 'Everipedia' },
        { src: dictionaryLogo, alt: 'Dictionary.com', name: 'Dictionary.com' },
    ];

    return (
        <React.Fragment >
            <div className="hoeBa_kie hoemNew"
                style={{
                    height: window.innerHeight + "px"
                }}>
                <div>
                    <div className='firstie_hoem px-5'>

                        <div className='d-flex justify-content-around'>
                            <div>
                                <div className='largeContent' style={{ paddingTop: "18%" }}>
                                    Saroj Spectrum Super Search
                                </div>
                                <div className='smallContent'>
                                    Discover broader and deeper search results with unprecedented efficiency. Our platform harnesses cutting-edge technology to empower users to swiftly access a vast array of information.Equipped with advanced AI tools, diverse dictionaries, encyclopedias, and innovative features like video-based search and translation, we're reshaping the landscape of online search.
                                </div>
                                <div className='mainBtn mt-3' onClick={GotoSearch} style={{ zIndex: 99 }} >
                                    Get Started Now →
                                </div>
                            </div>

                            <div>
                                <div style={{ position: "relative", marginTop: "45%", right: "22%" }} >
                                    <img src={girl} style={{ width: "450px", height: "500px" }} />

                                    <div style={{ position: "absolute", top: "28%", left: "-20%" }}>
                                        <img src={searchio} onClick={() => setSearchImg(!searchImg)} className='roundCircleImg pointer' />
                                        <div>
                                            {searchImg &&
                                            <div style={{position:"relative"}}>
                                                <div style={{position:"absolute", marginTop:"-30%", left:"-25%" }}>
                                                    {/* <span style={{ color: "green", backgroundColor: "red" }}>Kalai</span> */}
                                                    <img src={yah} alt='' width={"70%"} />
                                                </div>
                                                <div style={{position:"absolute", marginTop:"12%", left:"-25%" }}>
                                                    {/* <span style={{ color: "green", backgroundColor: "red" }}>sriram</span> */}
                                                    <img src={google} alt='' width={"70%"} />
                                                </div>
                                                <div style={{position:"absolute", marginTop:"-10%", left:"7%" }}>
                                                    {/* <span style={{ color: "green", backgroundColor: "red" }}>sakthi</span> */}
                                                    <img src={luco} alt='' width={"70%"} />
                                                </div>
                                                <div style={{position:"absolute", marginTop:"25%", left:"20%" }} >
                                                    {/* <span style={{ color: "green", backgroundColor: "red" }}>ramesh</span> */}
                                                    <img src={duc} alt='' width={"70%"} />
                                                </div>
                                                <div style={{position:"absolute", marginTop:"-8%", left:"40%" }} >
                                                    {/* <span style={{ color: "green", backgroundColor: "red" }}>balaji</span> */}
                                                    <img src={vcode} alt='' width={"70%"} />
                                                </div>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div style={{ position: "absolute", top: "4%", left: "-5%" }}>
                                        <img src={aioio} className='roundCircleImg' />
                                    </div>
                                    <div style={{ position: "absolute", top: "-24%", left: "15%" }}>
                                        <img src={wikiiio} className='roundCircleImg' />
                                    </div>
                                    <div style={{ position: "absolute", top: "-34%", left: "46%" }}>
                                        <img src={translateiooio} className='roundCircleImg1' />
                                    </div>
                                    <div style={{ position: "absolute", top: "5%", left: "86%" }}>
                                        <img src={dictionaryiioio} style={{ width: "88px", height: "88px" }} />
                                    </div>
                                    <div style={{ position: "absolute", top: "38%", left: "95%" }}>
                                        <img src={newsssio} style={{ width: "90px", height: "90px" }} />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                    <div style={{ paddingTop: "3%" }}>
                        <div className='secondScale'>
                            Organizations We Collaborate
                        </div>

                        <div className='w-100 d-flex flex-column align-items-center justify-content-center'>
                            <div className='sedondScaletwo'>
                                with for universal data
                            </div>
                            <div className='sedondScaletwo'>
                                search
                            </div>
                        </div>
                    </div>


                    <div style={{ paddingTop: "3%" }}>
                        <div className='d-flex justify-content-center align-items-center'>
                            <div className='thirdCardScale mb-4'>
                                <div className="cardSD-container">
                                    {cards.map((card, index) => (
                                        <div className="cardSD" key={index}>
                                            <div className="cardSD-title">{card.title}</div>
                                            <img src={card.imgSrc} alt={card.title} />
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>


                    <div style={{ paddingTop: "3%" }}>

                        <div className="w-100 d-flex align-items-center">
                            <div className="row justify-content-center gap-3">
                                {contents.map((x) => (
                                    <div className="col-md-2 mb-3" key={x.head}>
                                        <div className="headCol mb-2">
                                            {x.head}
                                        </div>
                                        <div className="subCol">
                                            {x.sub}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>


                    </div>



                    <div style={{ paddingTop: "3%" }}>
                        <div className="sarojini-container">
                            <div className="sarojini-content">
                                <img src={laptopImage} alt="Laptop with Sarojini Search Engine" className="sarojini-image" />
                                <div className="sarojini-text">
                                    <h2>Why Choose Sarojini ?</h2>
                                    <p>Sarojini distinguishes itself in the competitive search engine market by providing a wide range of options to guarantee accurate search results. Whether you are using the free version or have a premium subscription, we offer unique sections customized to suit your requirements.</p>
                                    <p>Additionally, our user-friendly platform is optimized to work smoothly on any device, allowing you to effortlessly access and explore information, no matter what technology you are using.</p>
                                </div>
                            </div>
                        </div>


                    </div>


                    <div style={{ paddingTop: "3%" }}>
                        <div className='productour'>Our Products</div>

                        <div className="container text-center">
                            <div className="d-flex justify-content-around align-items-center" >
                                <div style={{ marginTop: "5%" }}>
                                    <img src={roundCircle} style={{ width: "100%", height: "450px" }} />
                                </div>
                                <div>
                                    {listItems.map((item, index) => (
                                        <div className='d-flex align-items-center gap-4 mb-3' key={index}>
                                            <div><img src={item.number} alt="" /></div>
                                            <div className='olSection'>{item.name}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>




                    <div style={{ paddingTop: "3%" }}>
                        <div className='download_BackGround'>
                            <div className='downloadHead'>Download Sarojini Spectrum Super Search </div>
                            <div className='d-flex justify-content-center align-items-center'>
                                <div className='download_second_back'>
                                    <div className='container text-center'>
                                        <div className='d-flex flex-row justify-content-center align-items-center gap-4'>
                                            <div>
                                                <img src={andriod} alt="andriod" tyle={{ width: "200px", height: "200px" }} />
                                            </div>
                                            <div className='downloadSection_content'>
                                                Get the Sarjini Search app on your Android device now and enjoy a smooth searching experience like never before. Our user-friendly interface and advanced features make it easier than ever to find what you're looking for. Whether you're on the move or relaxing at home, RAJI keeps you connected to the information you need, right at your fingertips.
                                            </div>

                                            <div className='dividder'></div>

                                            <div>
                                                <img src={laphone} alt="laphone" style={{ width: "430px", height: "215px" }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div style={{ padding: "3% 0" }}>
                        <div className='d-flex justify-content-center'>
                            <div className='outrustHead'>Our Trusted Partners</div>
                        </div>
                        <div className='container' style={{ paddingTop: "2%" }}>
                            <div className='d-flex justify-content-center'>
                                <div className='outrustsubContetn'>We proudly expand our network of reliable partners, including industry giants, to enhance your search experience. Our commitment to building strong partnerships ensures comprehensive and dependable results.</div>
                            </div>
                        </div>
                        <div className='container' style={{ paddingTop: "2%" }}>
                            <div className='d-flex justify-content-center'>
                                <div className="logo-gallery">
                                    {logos.map((logo, index) => (
                                        <div key={index} className="logo-item">
                                            <img src={logo.src} alt={logo.alt} className="logo-image" />
                                            <p className="logo-name">{logo.name}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>

                    <Footer />

                </div>
            </div>


        </React.Fragment >
    )
}

export default Home;