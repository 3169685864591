export default {
    searchplaceholder: "सरोज खोज",
    languages: "भाषाएँ",
    home: "होम स्क्रीन",
    product:"उत्पादों",
    support:"सहायता",
    recentnews:"नवीनतम समाचार",
    settings:"समायोजन",
    about:"के बारे में",
    contact:"संपर्क",
    bookmarks: "पुस्तक चिह्नित करें",
    tabs: "टैब्स",
    settings: "सेटिंग्स",
    more: "अधिक",
    profile: "प्रोफ़ाइल",
    logout: "लॉग आउट",
    emailNotifications: "ईमेल सूचनाएँ",
    pushNotifications: "पुश सूचनाएँ",
    whatsappNotifications: "केवल व्हाट्सएप सूचनाएं",
    history: "इतिहास",
    changeLanguage: "भाषा बदलें",
    feedback: "प्रतिक्रिया",
    Termsandconditions: "नियम और शर्तें",
    slogan:"बस एक क्लिक से दुनिया का अन्वेषण करें और अपनी खोज शुरू करें",
    trending:"आज ट्रेंडिंग में है"
}