import React from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import aboutside from '../Assets/Rectangle 34624412.png';
import chatgpt from "../Assets/Rectangle 34624413.png";
import gemini from "../Assets/Rectangle 34624414.png";
import newone from "../Assets/Rectangle 34624415.png";
import wiki from "../Assets/Rectangle 34624416.png";
import two from "../Assets/Rectangle 34624417.png";
import Header1 from '../Header/Header1';

const About = () => {
    const iamges = [
        { img: chatgpt }, { img: gemini }, { img: newone }, { img: wiki }, { img: two }
    ]
    return (
        <>
            <Header1 />
            <Row>
                <Col sm={12} md={6} lg={12} className='mt-5'>
                    <div style={{ color: "#EA596E", paddingLeft: "10%" }} >
                        About
                    </div>
                    <div className='KP_Font' style={{ paddingLeft: "10%" }}> Our Story</div>
                    <div className='about-text' style={{ paddingLeft: "10%" }}>
                        Introducing our revolutionary multi-faceted search engine, designed to deliver broader and deeper search
                        results with unprecedented efficiency. Harnessing cutting-edge technology, our platform empowers users to swiftly access a vast array of information. Equipped with advanced AI tools, diverse dictionaries, encyclopedias, and innovative features like video-based search and translation, we're reshaping the landscape of online search. Our refined search engine allows users to explore context-specific data at a granular level, providing insights and answers tailored to their needs. With the option of voice search, accessing information is easier and more intuitive than ever before. Whether you're seeking specific facts or exploring new possibilities, our search engine is your gateway to a world of knowledge. Experience the future of search with our comprehensive and dynamic platform, where every query leads to discovery.
                    </div>
                    <img src={aboutside} width={"500px"} height={'350px'} style={{ position: "absolute", right: "1%", top: "10%" }} />
                </Col>
                <Col sm={12} md={6} lg={12}>
                    <div className='pt-3'>
                        <div className='we-support text-center'>Organizations we support</div>
                    </div>
                    <div className='d-flex align-items-center flex-row justify-content-center gap-3 pt-3'>

                        {iamges.map((x, i) => (
                            <span> <img src={x.img} alt='' /></span>
                        ))}

                    </div>
                </Col>
            </Row>
        </>
    )
}

export default About
