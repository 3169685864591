export default {
    searchplaceholder: "ಸರೋಜ್ ಹುಡುಕಾಟ",
    languages: "ಭಾಷೆಗಳು",
    home: "ಮುಖಪುಟ ಪರದೆ",
    product:"ಉತ್ಪನ್ನಗಳು",
    support:"ಬೆಂಬಲ",
    recentnews:"ಇತ್ತೀಚಿನ ಸುದ್ದಿ",
    settings:"ಸಂಯೋಜನೆಗಳು",
    about:"ಬಗ್ಗೆ",
    contact:"ಸಂಪರ್ಕಿಸಿ",
    bookmarks: "ಬುಕ್ಮಾರ್ಕ್‌ಗಳು",
    tabs: "ಟ್ಯಾಬ್‌ಗಳು",
    settings: "ಸೆಟ್ಟಿಂಗ್ಗಳು",
    more: "ಹೆಚ್ಚು",
    profile: "ಪ್ರೊಫೈಲ್",
    logout: "ಲಾಗ್ ಔಟ್",
    emailNotifications: "ಇಮೇಲ್ ಅಧಿಸೂಚನೆಗಳು",
    pushNotifications: "ಪುಶ್ ಅಧಿಸೂಚನೆಗಳು",
    whatsappNotifications: "ವಾಟ್ಸ್ಯಾಪ್ ಅಧಿಸೂಚನೆಗಳು",
    history: "ಇತಿಹಾಸ",
    changeLanguage: "ಭಾಷೆ ಬದಲಿಸಿ",
    feedback: "ಪ್ರತಿಕ್ರಿಯೆ",
    Termsandconditions: "ಷರತ್ತುಗಳು ಮತ್ತು ನಿಯಮಗಳು",
    slogan:"ಕೇವಲ ಒಂದು ಕ್ಲಿಕ್‌ನಲ್ಲಿ ಜಗತ್ತನ್ನು ಅನ್ವೇಷಿಸಿ ಮತ್ತು ನಿಮ್ಮ ಹುಡುಕಾಟವನ್ನು ಪ್ರಾರಂಭಿಸಿ",
    trending:"ಇಂದು ಟ್ರೆಂಡಿಂಗ್"
}