import React, { useState, useRef, useEffect } from "react";
import { AiOutlineExpand } from "react-icons/ai";
import { MdChevronLeft, MdChevronRight } from "react-icons/md";
import { IoHomeOutline, IoClose } from "react-icons/io5";
import { FiMinimize } from "react-icons/fi";

const Iframe = ({index, expandedIndex, isMobile, Browsers, browser, handleExpand, handleClose}) => {

  const newiframeref = useRef(null);

  return (
    <div
      key={index}
      className={"col-12"}
    //   className={
    //     isMobile && expandedIndex === null && index === 0
    //       ? "col-12"
    //       : isMobile && expandedIndex === null && index === 1
    //       ? "d-none"
    //       : expandedIndex === null
    //       ? `col-${Browsers.length > 1 ? 6 : 8} p-1`
    //       : expandedIndex === index
    //       ? "col-12"
    //       : `col-${Browsers.length > 1 ? 6 : 8} p-1 d-none`
    //   }
    >
      <div
        className="w-100 shadow-sm fullViewHeight"
        style={{
          height: window.innerHeight - 125 + "px",
        }}
      >
        <div className="d-flex flex-row align-items-center justify-content-between p-2 border-bottom headCollio">
          <div className="d-flex align-items-center gap-2">
            <MdChevronLeft
              className="pointer"
              size={24}
              onClick={() => {
                // if (newiframeref.current) {
                    // var iframe = document.getElementById(browser.id);
                    // var iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
                    // var iframeUrl = iframeDocument.URL;

                    // console.log(iframeUrl,"document url");

                    // console.log(newiframeref.current.src,"current src")
                    // window.history.back();
                    // newiframeref.current.back("-1");
                    // newiframeref.current.contentWindow.history.back();
                    // newiframeref.current.contentWindow.postMessage('goback', '*');
                    document.getElementById("iframeid1").contentWindow.history.back();
                    // iframe.contentWindow.history.back();
                // }
              }}
            />
            <IoHomeOutline
              className="pointer"
              size={18}
              onClick={() => {
                if (newiframeref.current) {
                  newiframeref.current.src = newiframeref.current.src;
                }
              }}
            />
            <MdChevronRight
              className="pointer"
              size={24}
              onClick={() => {
                // if (newiframeref.current) {
                  window.history.forward();
                // }
              }}
            />
          </div>
          <div className="text-center">
            <h5>{"Wikipedia"}</h5>
          </div>
          <div className="d-flex align-items-center gap-3">
            {/* {!isMobile && (
              <span className="pointer" onClick={() => handleExpand(index)}>
                {expandedIndex === index ? (
                  <FiMinimize size={"1em"} />
                ) : (
                  <AiOutlineExpand size={"1em"} />
                )}
              </span>
            )} */}
            <IoClose
              size={"1.3em"}
            //   onClick={() => handleClose(index)}
              className="pointer"
            />
          </div>
        </div>
        <iframe
          style={{
            height: window.innerHeight - 170 + "px",
          }}
          src={"https://en.wikipedia.org/wiki/Production"}
          loading="lazy"
          frameborder="0"
          allowtransparency="true"
        //   title={`view-${index}`}
          className="w-100 iframe_height"
          id={"iframeid1"}
          ref={newiframeref}
        />
      </div>
    </div>
  );
};

export default Iframe;
