export default {
    searchplaceholder: "ساروج تلاش",
    languages: "زبانیں",
    home: "گھر کی سکرین",
    product:"مصنوعات",
    support:"حمایت",
    recentnews:"حالیہ خبریں",
    about:"کے بارے میں",
    contact:"رابطہ",
    bookmarks: "بک مارکس",
    tabs: "ٹیبز",
    settings: "سیٹنگز",
    more: "مزید",
    profile: "پروفائل",
    logout: "لاگ آوٹ",
    emailNotifications: "ای میل اطلاعات",
    pushNotifications: "پش اطلاعات",
    whatsappNotifications: "واٹس ایپ اطلاعات صرف",
    history: "تاریخ",
    changeLanguage: "زبان تبدیل کریں",
    feedback: "آراء",
    Termsandconditions: "شرائط و ضوابط",
    slogan:"صرف ایک کلک کے ساتھ دنیا کو دریافت کریں اور اپنی تلاش شروع کریں۔",
    trending:"آج کا رجحان "
}