export default {
    searchplaceholder: "సరోజ్ శోధన",
    languages: "భాషలు",
    home: "హోమ్ స్క్రీన్",
    product:"ఉత్పత్తులు",
    support:"మద్దతు",
    recentnews:"ఇటీవలి వార్తలు",
    about:"గురించి",
    contact:"సంప్రదించండి",
    bookmarks: "బుక్మార్క్స్",
    tabs: "ట్యాబ్‌లు",
    settings: "సెట్టింగ్లు",
    more: "మరింత",
    profile: "ప్రొఫైల్",
    logout: "లాగ్ అవుట్",
    emailNotifications: "ఇమెయిల్ నోటిఫికేషన్లు",
    pushNotifications: "పుష్ నోటిఫికేషన్లు",
    whatsappNotifications: "వాట్సాప్ నోటిఫికేషన్లు",
    history: "చరిత్ర",
    changeLanguage: "భాషను మార్చు",
    feedback: "అభిప్రాయం",
    Termsandconditions: "షరతులు మరియు ప్రమాణాలు",
    slogan:"కేవలం ఒక క్లిక్‌తో ప్రపంచాన్ని అన్వేషించండి మరియు మీ శోధనను ప్రారంభించండి",
    trending:"ఈరోజు ట్రెండింగ్"

}