import './App.css';
import './NewStyle.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from './components/Login';
import Signup from './components/Signup';
import '../src/components/Styles.css'
import MainHomepage from './components/home/MainHomepage';
import Search from './components/search/Search';
import Iframe from './components/Iframe';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Products from './components/Products/Products';
import Header from './components/Header/Header';
import Support from './components/Support/Support';
import About from './components/About/About';
import Contact from './components/ContactUs/Contact';
import Header1 from './components/Header/Header1';
import { useState } from 'react';

function App() {

  const AuthUser = localStorage.getItem("Authuser");

  const [showbookmarks, setShowBookMarks] = useState({
    bookmark: false,
    history: false
  });

  return (
    <Router>
      <div>
        {/* <Header /> */}
        <Header1 showbookmarks={showbookmarks}
          setShowBookMarks={setShowBookMarks} 
        />
        <Routes>
          {/* <Route path="/login" element={<Login />} /> */}
          {/* <Route path="/signup" element={<Signup />} /> */}
          <Route>
            <Route path='/' element={<MainHomepage />} />
            <Route path='/search'
              element={<Search showbookmarks={showbookmarks}
                setShowBookMarks={setShowBookMarks}
                 />} />
            <Route path='/iframe' element={<Iframe />} />
            <Route path='/products' element={<Products />} />
            <Route path='/support' element={<Support />} />
            <Route path='/about' element={<About />} />
            <Route path='/contact' element={<Contact />} />
          </Route>
        </Routes>
        <ToastContainer />
      </div>
    </Router>
  );
}

export default App;