export default {
    searchplaceholder: "Saroj Search",
    languages: "Languages",
    home: "Home",
    product:"Products",
    bookmarks: "Bookmarks",
    support:"Support",
    recentnews:"Recent News",
    settings:"Settings",
    about:"About",
    contact:"Contact",
    tabs: "Tabs",
    settings: "Settings",
    more: "More",
    profile: "Profile",
    logout: "Log out",
    emailNotifications: "Email notifications",
    pushNotifications: "Push Notifications",
    whatsappNotifications: "Only Whatsapp Notifications",
    history: "History",
    changeLanguage: "Change Language",
    feedback: "Feedback",
    Termsandconditions: "Terms and conditions",
    Welcometoreact: "Welcome to React",
    slogan:"Explore the World with Just One Click and Start Your Search",
    trending:"Today Trending"
}