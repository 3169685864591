export default {
    searchplaceholder: "சரோஜ் தேடல்",
    languages: "மொழிகள்",
    home: "முகப்புத் திரை",
    product:"தயாரிப்புகள்",
    support:"ஆதரவு",
    recentnews:"சமீபத்திய செய்திகள்",
    about:"பற்றி",
    contact:"தொடர்பு",
    bookmarks: "புத்தக குறிப்புகள்",
    tabs: "தாவல்கள்",
    settings: "அமைப்புகள்",
    more: "மேலும்",
    profile: "சுயவிவரம்",
    logout: "வெளியேறு",
    emailNotifications: "மின்னஞ்சல் அறிவிப்புகள்",
    pushNotifications: "புஷ் அறிவிப்புகள்",
    whatsappNotifications: "வாட்ஸ்அப் அறிவிப்புகள் மட்டுமே",
    history: "வரலாறு",
    changeLanguage: "மொழியை மாற்று",
    feedback: "கருத்துகள்",
    Termsandconditions: "விதிமுறைகள் மற்றும் நிபந்தனைகள்",
    slogan:"ஒரே கிளிக்கில் உலகை ஆராய்ந்து உங்கள் தேடலைத் தொடங்குங்கள்",
    trending:"இன்று டிரெண்டிங்"
}