import React from "react";
import { Col, Form } from "react-bootstrap";
import moment from "moment";

const Input1 = ({
    label,
    type,
    placeholder,
    name,
    className,
    value,
    textFunc,
    rowClassName,
    labelClassName,
    readOnly,
    maxDate,
    required,
}) => {
    return (
        <Form.Group className="mb-3" controlId="formHorizontalEmail">
            <Form.Label className={labelClassName ? labelClassName : "text-center"}>
                {label} {required && <span className={"required-label"}>*</span>}
            </Form.Label>
            <Col sm={12}>
                <Form.Control
                    type={type}
                    placeholder={placeholder}
                    name={name}
                    onChange={(e) => textFunc(e.target.value)}
                    value={value}
                    className={`form-control ${className}`}
                    readOnly={readOnly}
                    max={type === "date" ? maxDate : ""}
                    autoComplete="off"
                />
            </Col>
        </Form.Group>
    );
};

export default Input1;

// Set default props
Input1.defaultProps = {
    readOnly: false,
    maxDate: moment().format("YYYY-MM-DD"), // Setting today's date as default maxDate
    required: false
};
