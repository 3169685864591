export default {
    searchplaceholder: "സരോജ് തിരിച്ചുവിടുക",
    languages: "ഭാഷകൾ",
    home: "ഹോം സ്‌ക്രീൻ",
    product:"ഉൽപ്പന്നങ്ങൾ",
    support:"പിന്തുണ",
    recentnews:"സമീപകാല വാർത്തകൾ",
    about:"കുറിച്ച്",
    contact:"ബന്ധപ്പെടുക",
    bookmarks: "ബുക്ക്മാർക്കുകൾ",
    tabs: "ടാബുകൾ",
    settings: "സെറ്റിംഗുകൾ",
    more: "കൂടുതൽ",
    profile: "പ്രൊഫൈൽ",
    logout: "ലോഗൌട്ട്",
    emailNotifications: "ഇമെയിൽ അറിയിപ്പുകൾ",
    pushNotifications: "പുഷ് അറിയിപ്പുകൾ",
    whatsappNotifications: "വാട്സാപ്പ് അറിയിപ്പുകൾ",
    history: "ചരിത്രം",
    changeLanguage: "ഭാഷ മാറ്റുക",
    feedback: "പ്രതികൃതി",
    Termsandconditions: "നിബന്ധനകൾ മറ്റും നിയമങ്ങൾ",
    slogan:"ഒരു ക്ലിക്കിലൂടെ ലോകം പര്യവേക്ഷണം ചെയ്‌ത് നിങ്ങളുടെ തിരയൽ ആരംഭിക്കുക",
    trending:"ഇന്നത്തെ ട്രെൻഡിംഗ്"

}