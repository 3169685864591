import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import sideimg from "../Assets/loginside.png"

function Modalc({ show, onClick, leftcontent, rightcontent, size,className }) {


    return (
        <>
            <Modal show={show} onHide={onClick} size={size} centered>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col xs={12} md={6} lg={6} className='p-0 full-height-col'>
                                {leftcontent}
                            </Col>
                            <Col xs={12} md={6} lg={6} className={`full-height-col d-flex align-items-center justify-content-center ${className}`}>
                                {rightcontent}
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    );
}

Modalc.defaultProps = {
    show: false,
    onClick: () => { },
    leftcontent: "",
    rightcontent: "",
    className:"",
    size: "lg",
}

export default Modalc;