export default {
    searchplaceholder: "ସରୋଜ ଅନୁସନ୍ଧାନ",
    languages: "ଭାଷାଗୁଡିକ",
    home: "ହୋମ ସ୍କ୍ରିନ୍",
    product:"ଉତ୍ପାଦଗୁଡିକ",
    support:"ସମର୍ଥନ",
    recentnews:"ସାମ୍ପ୍ରତିକ ଖବର",
    about:"ବିଷୟରେ",
    contact:"ଯୋଗାଯୋଗ କରନ୍ତୁ |",
    bookmarks: "ବୁକମାର୍କ୍‌ଗୁଡ଼ିକ",
    tabs: "ଟାବ୍‌ଗୁଡ଼ିକ",
    settings: "ସେଟିଂସ୍",
    more: "ଅଧିକ",
    profile: "ପ୍ରୋଫାଇଲ",
    logout: "ଲଗଆଉଟ୍",
    emailNotifications: "ଇମେଲ୍ ବିଜ୍ଞପ୍ତି",
    pushNotifications: "ପୁଶ୍ ବିଜ୍ଞପ୍ତି",
    whatsappNotifications: "ୱାଟସଆପ୍ ବିଜ୍ଞପ୍ତି",
    history: "ଇତିହାସ",
    changeLanguage: "ଭାଷା ବଦଳାଇବା",
    feedback: "ପ୍ରତିକ୍ରିୟା",
    Termsandconditions: "ଶର୍ତାଏଂ ଓ ଶ୍ରେଷ୍ଠ",
    slogan:"କେବଳ ଗୋଟିଏ କ୍ଲିକ୍ ସହିତ ବିଶ୍ Expl କୁ ଏକ୍ସପ୍ଲୋର୍ କରନ୍ତୁ ଏବଂ ଆପଣଙ୍କର ସନ୍ଧାନ ଆରମ୍ଭ କରନ୍ତୁ |",
    trending:"ଆଜି ଟ୍ରେଣ୍ଡିଂ |"
}