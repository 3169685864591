import React, { useState } from 'react'
import Home from './Home'
import Header1 from '../Header/Header1'

const MainHomepage = () => {
  const [modalshow, setModalShow] = useState(false);

  return (
    <div>
      <Header1 modalshow={modalshow} setModalShow={setModalShow} />
      <Home loginCall={() => setModalShow(true)} />
    </div>
  )
}

export default MainHomepage
