import { LocalPointURL } from "./ServiceURL";

export async function PostUrl(body) {
    try {
      const response = await fetch(`${LocalPointURL}/history/add`, {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      if (response.status === 200 || response.status === 201) {
        return await response.json();
      } else if (
        response.status === 401 ||
        response.status === 400 ||
        response.status === 404 ||
        response.status === 500
      ) {
        return await response.json();
      } else if (response.status === 403) {
        return await response.json();
      } else {
        var errorResponse = await response.json();
        throw new Error(errorResponse.error);
      }
    } catch (error) { }
  }

  export async function GetUrl(userId) {
    try {
      const response = await fetch(`${LocalPointURL}/history/getall/${userId}`, {
        method: `GET`,
        headers: {
          "Content-Type": "application/json",
        }
      });
      if (response.status === 200) {
        return await response.json();
      } else if (
        response.status === 401 ||
        response.status === 400 ||
        response.status === 404
      ) {
        return await response.json();
      } else if (response.status === 403) {
        return await response.json();
      } else {
        var errorResponse = await response.json();
        throw new Error(errorResponse.error);
      }
    } catch (error) {}
  }

  export async function HistoryRemove(body) {
    try {
      const response = await fetch(`${LocalPointURL}/history/delete`, {
        method: `DELETE`,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      if (response.status === 200 || response.status === 201) {
        return await response.json();
      } else if (
        response.status === 401 ||
        response.status === 400 ||
        response.status === 404 ||
        response.status === 500
      ) {
        return await response.json();
      } else if (response.status === 403) {
        return await response.json();
      } else {
        var errorResponse = await response.json();
        throw new Error(errorResponse.error);
      }
    } catch (error) { }
  }

  export async function BookmarkPost(body) {
    try {
      const response = await fetch(`${LocalPointURL}/bookmark/add`, {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      if (response.status === 200 || response.status === 201) {
        return await response.json();
      } else if (
        response.status === 401 ||
        response.status === 400 ||
        response.status === 404 ||
        response.status === 500
      ) {
        return await response.json();
      } else if (response.status === 403) {
        return await response.json();
      } else {
        var errorResponse = await response.json();
        throw new Error(errorResponse.error);
      }
    } catch (error) { }
  }

  export async function BookmarkRemove(body) {
    try {
      const response = await fetch(`${LocalPointURL}/bookmark/remove`, {
        method: `DELETE`,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      if (response.status === 200 || response.status === 201) {
        return await response.json();
      } else if (
        response.status === 401 ||
        response.status === 400 ||
        response.status === 404 ||
        response.status === 500
      ) {
        return await response.json();
      } else if (response.status === 403) {
        return await response.json();
      } else {
        var errorResponse = await response.json();
        throw new Error(errorResponse.error);
      }
    } catch (error) { }
  }

  export async function GetBookmartData(body) {
    try {
      const response = await fetch(`${LocalPointURL}/bookmark/get`, {
        method: `POST`,
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      if (response.status === 200) {
        return await response.json();
      } else if (
        response.status === 401 ||
        response.status === 400 ||
        response.status === 404
      ) {
        return await response.json();
      } else if (response.status === 403) {
        return await response.json();
      } else {
        var errorResponse = await response.json();
        throw new Error(errorResponse.error);
      }
    } catch (error) {}
  }
