export default {
    searchplaceholder: "সরোজ অনুসন্ধান",
    languages: "ভাষা",
    home: "হোম",
    product:"পণ্য",
    bookmarks: "বুকমার্ক",
    support:"সমর্থন",
    recentnews:"সাম্প্রতিক খবর",
    settings:"সেটিংস",
    about:"সম্পর্কিত",
    contact:"যোগাযোগ",
    tabs: "ট্যাব",
    settings: "সেটিংস",
    more: "আরও",
    profile: "প্রোফাইল",
    logout: "লগ আউট",
    emailNotifications: "ইমেল বিজ্ঞপ্তি",
    pushNotifications: "পুশ বিজ্ঞপ্তি",
    whatsappNotifications: "হোয়াটসঅ্যাপ বিজ্ঞপ্তি মাত্র",
    history: "ইতিহাস",
    changeLanguage: "ভাষা পরিবর্তন করুন",
    feedback: "মতামত",
    Termsandconditions: "শর্তাবলী এবং শর্তাদি",
    slogan:"শুধু এক ক্লিকে বিশ্ব অন্বেষণ করুন এবং আপনার অনুসন্ধান শুরু করুন৷",
    trending:"আজকের ট্রেন্ডিং"
}