import React from 'react'
import { Col, Row } from 'react-bootstrap';
import playstore from '../Assets/app-android-en.png.png';
import appstore from '../Assets/app-ios-en.png.png';
import logo from "../Assets/twemoji_lotus.png";
import fb from '../Assets/footer-facebook.png';
import insta from '../Assets/SVG.png';
import linked from '../Assets/Link.png';
import twitter from '../Assets/Link (1).png';
import whatsapp from '../Assets/Link (2).png';

const Footer = () => {

    const aboutsaro = [{ name: "Introduction" }, { name: "Overview" }, { name: "Saroj for Individuals" }, { name: "Careers" }, { name: "Contact us" }]
    const termssaro = [{ name: "API Policy" }, { name: "Privacy Policy" }, { name: "Documentation Policy" }, { name: "Safety Standards Policy" }, { name: "Terms of Use Policy" }]
    const brandsaro = [{ name: "Logo / Trademarks" }, { name: "Advertising" }, { name: "Careers" }, { name: "Support" }]
    const image = [{ img: fb }, { img: insta }, { img: linked }, { img: twitter }, { img: whatsapp }]


    return (
        <div className='footer_back py-5'>
            <Row className='d-flex justify-content-center align-items-center height_inherit'>
                <Col className='d-flex justify-content-end'sm={12} md={4} lg={3}>
                    <div className='footer_head'>
                        <div> About Sarojini Spectrum</div>
                        <div className='d-flex flex-column gap-3 Footer_insidefont'>
                            {aboutsaro.map((x, i) =>
                                <span className='pointer'> {x.name}</span>
                            )}
                        </div>
                    </div>
                </Col>
                <Col className='d-flex justify-content-center' sm={12} md={4} lg={3}>
                    <div className='footer_head'>
                        <div>Terms & Policies</div>
                        <div className='d-flex flex-column gap-3 Footer_insidefont'>
                            {termssaro.map((x, i) =>
                                <span className='pointer'>{x.name}</span>
                            )}
                        </div>
                    </div>
                </Col>
                <Col>
                    <div className='footer_head pb-4' sm={12} md={4} lg={3}>
                        <div>Brand Information</div>
                        <div className='d-flex flex-column gap-3  Footer_insidefont'>
                            {brandsaro.map((x, i) =>
                                <span className='pointer'>{x.name}</span>
                            )}
                        </div>
                    </div>
                </Col>
                <Col className='pb-5' sm={12} md={4} lg={3}>
                    <div className='footer_head'> Sarojini Spectrum app</div>
                    <div className='d-flex flex-column gap-2'>
                        <img src={playstore} alt='' width={"150px"} className='pointer'/>
                        <img src={appstore} alt='' width={"150px"} className='pointer'/>
                    </div>
                </Col>
            </Row>
            <Row style={{ backgroundColor: "#000" }}>
                <Col className='d-flex align-items-center justify-content-center gap-2 pt-3' sm={12} md={12} lg={6}>
                    <span><img src={logo} alt='' width={"50px"} /></span>
                    <div className='d-flex gap-4 Footer_insidefont'>
                        <span className='pointer'>
                            About Sarojini Spectrum
                        </span>
                        <span className='pointer'>
                            Imprint
                        </span>
                        <span className='pointer'>
                            Data privacy
                        </span>
                    </div>
                </Col>
                <Col className='d-flex gap-4 pt-4'sm={12} md={12} lg={6}>
                    <div className='d-flex gap-3 '>
                        {image.map((x, i) =>
                            <span className='pointer'><img src={x.img} alt='' /></span>
                        )}
                    </div>
                    <div className='Footer_insidefont'>
                        © Sarojini Spectrum , 2024
                    </div>

                </Col>
            </Row>
        </div>
    )
}

export default Footer

