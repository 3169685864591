import React from 'react'
import './Products.css'
import { Container, Row, Col, Card } from "react-bootstrap";
import supersearch from '../Assets/Rectangle 34624389.png';
import Artificial from '../Assets/arti.png';
import encyclo from '../Assets/encyco.png';
import tarnslate from '../Assets/translate.png';
import dictionary from '../Assets/dictonaryone.png';
import news from '../Assets/newsone.png';
import Header1 from '../Header/Header1';


const Products = () => {

    const ListedDate = [
        {
            image: supersearch,
            Title: "Super Search Engines",
        },
        {
            image: Artificial,
            Title: "Artificial Intelligences Super Search",
        },
        {
            image: encyclo,
            Title: "Encyclopedias Super Search",
        },
        {
            image: tarnslate,
            Title: "Translators Super Search",
        },
        {
            image: dictionary,
            Title: "Dictionaries Super Search",
        },
        {
            image: news,
            Title: "News Super Search",
        },
    ]

    return (
        <div>
            <Header1 />
            <Row className='d-flex gap-2'>
                <div className='product-font pt-5'>
                    Our Products
                </div>
                <div className='d-flex flex-row justify-content-center align-items-center flex-wrap gap-4'>
                    {ListedDate.map((x, i) => (
                        <Card className="d-flex pointer mb-2 ">
                            <Card.Img variant="top" src={x.image} className='border-radius' />
                            <Card.Body className="text-center ">
                                <div className='title_size '>{x.Title}</div>
                            </Card.Body>
                        </Card>
                    ))}
                </div>
            </Row>
        </div>
    )
}

export default Products

