import React, { useState, useRef } from "react";
import { Form } from "react-bootstrap";
import moment from "moment";
import "../Styles.css";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { RiVerifiedBadgeFill } from "react-icons/ri";

const Input = ({
    label,
    type,
    placeholder,
    otpOnChange,
    name,
    className,
    value,
    textFunc,
    rowClassName,
    labelClassName,
    maxLength,
    readOnly,
    minDate,
    required,
    showicon,
    iconStyle,
    showverify,
    disabled,
    errorMessage,
    iconBottomPosition,
    inputRef // Add inputRef to receive ref prop
}) => {
    const [showPassword, setShowPassword] = useState(false);

    const handleChange = (e) => {
        if (otpOnChange) {
            otpOnChange(e);
        } else if (textFunc) {
            textFunc(e);
        }
    };

    return (
        <Form.Group className={rowClassName ? rowClassName : ""} controlId="formBasicUsername">
            <Form.Label className={labelClassName ? labelClassName : "text-center"}>
                {label} {required && <span className={"required-label"}>*</span>}
            </Form.Label>
            <Form.Control
                ref={inputRef} // Attach ref to input
                type={showPassword ? 'text' : type}
                placeholder={placeholder}
                name={name}
                onChange={handleChange}
                value={value}
                readOnly={readOnly}
                className={`form-control ${className}`}
                min={type === "date" ? minDate : ""}
                autoComplete="off"
                maxLength={maxLength}
                disabled={disabled}
            />
            {showicon && (
                <span
                    style={iconStyle}
                    onClick={() => setShowPassword(!showPassword)}
                >
                    {showPassword ? <AiOutlineEye size={22} /> : <AiOutlineEyeInvisible size={22} />}
                </span>
            )}
            {showverify && (
                <span
                    style={{ position: "relative", bottom: "30px", left: "76%" }}
                >
                    <div className="d-flex align-items-center gap-1">
                        <div>
                            <RiVerifiedBadgeFill
                                size={23}
                                className="IconColor_Green"
                            />
                        </div>
                        <div className="Verify_Style_Green">
                            Verified
                        </div>
                    </div>
                </span>
            )}
            {errorMessage && <div className="error-message">{errorMessage}</div>} {/* Display error message */}
        </Form.Group>
    );
};

Input.defaultProps = {
    readOnly: false,
    minDate: moment().format("YYYY-MM-DD"),
    required: false,
    maxLength: "",
    value: "",
    otpOnChange: () => { },
    showicon: false,
    showverify: false,
    iconBottomPosition: "29.5%" // Default icon bottom position
};

export default Input;
