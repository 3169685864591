import React from 'react'
import { Col, Row } from 'react-bootstrap'
import background from "../Assets/Mask Group.png"
import Header1 from '../Header/Header1'

const Contact = () => {
    return (
        <>
            <Header1 />
            <Row>
                <div className='mt-5'>
                    <h2 className='text-center'>Contact</h2>
                    <Col>
                        <div className='d-flex'>
                            <div>
                                {/* <img src={background} alt='' /> */}
                            </div>


                        </div>
                    </Col>


                </div>
            </Row>
        </>
    )
}

export default Contact
