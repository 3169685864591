import React from "react";
import ReactSelect from "react-select";

const Select = ({
  label,
  className,
  labelClassName,
  placeholder,
  value,
  onChange,
  errorMessage,
  options,
  isDisabled,
}) => {

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected ? '#B24BD6' : 'white',
      color: state.isSelected ? 'white' : 'black',
      '&:hover': {
        backgroundColor: '#B24BD6',
        color: 'white',
      },
    }),
  };
  return (
    <div className="d-flex flex-column w-100 gap-1">
      <label className={` ${labelClassName} ? ${labelClassName} : "" `}>
        {label}
      </label>
      <ReactSelect
        value={value === "" ? null : value}
        onChange={onChange}
        className={`Dropdown_Back_Color ${className}`}
        options={options}
        isDisabled={isDisabled}
        placeholder={placeholder}
        labelledBy="Select"
        styles={customStyles}
      />
      <div className="err-msg">{errorMessage}</div>
    </div>
  );
};

Select.defaultProps = {
  value: "",
  onChange: () => { },
  errorMessage: "",
  label: "",
  placeholder: "",
  options: [],
  isDisabled: false,
  className: "",
};

export default Select;
